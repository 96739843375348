@use '../../variables' as var;

.sections-container {
    grid-column: 1 / 2;
    grid-row:  2 / 3;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 171px auto;
    row-gap: 20px;

    .header-container {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        border: thin solid var.$card-border;
    }

    .cumulative-producer {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .realtime-producer {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .cumulative-trunk {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .realtime-trunk {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .section-header {
        grid-row: 1 / 2;
        line-height: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;  
    }

    .sections-loader {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 100%;
        position: relative;
        z-index: 1;
        background-color: var.$primary-bg-color;

        .load-content {
            width: 30px;
            height: 30px;
            position: fixed;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
        }
    }
}

@media screen and (min-width: 540px) {
    .sections-container {
        grid-template-rows: 119px auto;
    }
}

@media screen and (min-width: 736px) {
    .sections-container {
        grid-template-rows: 78px auto;
    }
}

@media screen and (min-width: 1024px) {
    .sections-container {
        grid-template-columns: 
            minmax(20px, auto) 
            minmax(736px, 1275px) 
            minmax(20px, auto);

        .header-container {
            grid-column: 2 / 3;
        }

        .cumulative-producer {
            grid-column: 2 / 3;
        }

        .realtime-producer {
            grid-column: 2 / 3;
        }

        .cumulative-trunk {
            grid-column: 2 / 3;
        }
    
        .realtime-trunk {
            grid-column: 2 / 3;
        }

        .sections-loader {
            grid-column: 2 / 3;
        }
    }
}