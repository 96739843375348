@use '../../variables' as var;

.pressures-container {
    display: grid;
    grid-template-rows: auto calc(210px + 39px) 210px;

    .pressure-line {
        grid-row: 2;        // grid-row 1 is section-header
        padding: 20px 0;
        width: 100%;
    }

    .pressure-gradient {
        grid-row: 3;
        width: 100%;
    }
}