@use '../mixins';
@use '../variables' as var;

.emlusion-container {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    row-gap: 20px;

    .emulsion-body {
        padding: 20px;
        width: 100%;
        background-color: #FFFFFF;
        display: grid;
        border: thin solid var.$card-border;
        position: relative;

        .emulsion-loader {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(255,255,255,0.7);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .emulsion-header {
            margin: -1px 0 15px 0;
            width: 100%;
            font-weight: 600;
            font-size: 24px;
            text-align: center;
        }

        .emulsion-title {
            margin: 5px 0 10px 0;
            font-weight: 600;
            font-size: 16px;
        }

        form {
            display: grid;
            
            .emulsion-inputs {
                display: grid;
    
                label {
                    margin: 0 0 5px 0;
                }
            }
        }

        .emulsion-results {
            display: grid;

            .emulsion-output{
                display: grid;
                align-items: flex-start;
                align-content: flex-start;
                grid-template-columns: auto auto;
            }

            .emulsion-messages {
                margin: 15px 0 0 0;
                display: grid;
                align-items: flex-start;
                align-content: flex-start;

                & div:last-child{
                    margin: 0 !important;
                }

                .emulsion-message-alert {
                    margin: 0 0 15px 0;
                    @include mixins.alert-box(
                        var.$alert-color,
                        var.$alert-bg-color,
                        var.$alert-border-color
                    );
                }
                
                .emulsion-message-warning {
                    margin: 0 0 15px 0;
                    @include mixins.alert-box(
                        var.$warning-color,
                        var.$warning-bg-color,
                        var.$warning-border-color
                    );
                }
    
                .emulsion-message-success {
                    margin: 0 0 15px 0;
                    @include mixins.alert-box(
                        var.$success-color,
                        var.$success-bg-color,
                        var.$success-border-color
                    );
                }
            }
        }
    }
}

@media screen and (min-width: 500px) {
    .emlusion-container {
        .emulsion-body {
            form {
                .emulsion-inputs {
                    grid-template-columns: 1fr 1fr;
                    column-gap: 20px;
                }
            }

            .emulsion-results {
                grid-template-columns: 1fr 1fr;
    
                .emulsion-messages {
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 902px) {
    .emlusion-container {
        .emulsion-body {
            padding: 40px;
            max-width: 902px;
            border-radius: 5px;
        }
    }
}