.weekly-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto calc(316px + 20px);

    .weekly-chart {
        grid-row: 2;        // grid-row 1 is section-header
        padding: 20px 0 0 0;
        width: 100%;
    }
}