@use '../../variables' as var;

.spark-container {
    display: grid;
    grid-template-rows: auto auto;

    .spark-rows-container {
        grid-row: 2;    // grid-row 1 is section-header
        display: flex;
        flex-direction: column;
        
        .spark-row {
            height: 96px;
            padding: 20px 0;
            display: grid;
            grid-template-columns: 115px 1fr;
            grid-template-rows: 1fr;
            border-bottom: thin solid #F0F0F0;
    
            &:last-child {
                height: 75px;
                padding: 20px 0 0 0;
                border-bottom: none;
            }
    
            .spark-text {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-weight: 500;
        
                & span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; 
                }
    
                & p {
                    span:first-child {
                        font-size: 16px;
                        font-weight: 600;
                    }
    
                    span:last-child {
                        font-size: 11px;
                    }
                }
            }
    
            .spark-chart {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .spark-container {
        .spark-rows-container {
            .spark-row {
                grid-template-columns: 40% 60%;
            }
        }
    }
}