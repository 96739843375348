@use '../mixins';
@use '../variables' as var;

.profile-container {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3 auto);
    justify-items: center;
    row-gap: 20px;

    .profile-picture {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .profile-details {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .profile-password {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .profile-card-body {
        padding: 20px 20px 30px 20px;
        max-width: 400px;
        min-width: 250px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        border: thin solid var.$card-border;
        position: relative;

        .profile-header {
            margin: -1px 0 15px 0;
            width: 100%;
            font-weight: 600;
            font-size: 24px;
            text-align: center;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
        
            label {
                margin: 0 0 5px 0;
            }
        }

        .profile-success-message {
            margin: 15px 0 0 0;
            width: 100%;
            @include mixins.alert-box(
                var.$success-color,
                var.$success-bg-color,
                var.$success-border-color
            );
        }
        
        .profile-error-message {
            margin: 15px 0 0 0;
            width: 100%;
            @include mixins.alert-box(
                var.$alert-color,
                var.$alert-bg-color,
                var.$alert-border-color
            );
        }
    }
}

@media screen and (min-width: 400px) {
    .profile-container {
        .profile-card-body {
            border-radius: 5px;
        }
    }
}

@media screen and (min-width: 840px) {
    .profile-container {
        grid-template-columns: 400px 400px;
        grid-template-rows: auto auto auto;
        justify-content: center;
        align-items: flex-start;
        column-gap: 20px;
        row-gap: 20px;
    
        .profile-picture {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
    
        .profile-details {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
        }
    
        .profile-password {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }
    }
}

@media screen and (min-width: 1260px) {
    .profile-container {
        grid-template-columns: repeat(3 400px);
        grid-template-rows: auto;
    
        .profile-picture {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }
    
        .profile-details {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }
    
        .profile-password {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
        }
    }

}