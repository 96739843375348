@use '../mixins';
@use '../variables' as var;

.signin-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var.$primary-bg-color;
    background-image: url("../../images/signin-bg.jpg");
    background-position: center top;
    background-size: cover;
    overflow: hidden;

    .signin-header {
        height: 84px;
        width: 350px;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var.$darkMode-bg-color;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .signin-body-wrapper {
        width: 350px;
        margin: 0 10px;
        overflow: hidden;

        .signin-loading {
            height: 100%;
            width: 50%;
            position: absolute;
            background-color: #FFFFFF;
            opacity: 0.7;
            z-index: 1;
        }

        .signin-loading-password-rest {
            right: 0;
        }

        .signin-body {
            width: 700px;
            position: relative;
            right: 0px;
            display: grid;
            grid-template-columns: 350px 350px;
            grid-template-rows: auto;
            transition: right 0.4s ease-in-out 0s;

            .signin-warning-message {
                @include mixins.alert-box(
                    var.$warning-color,
                    var.$warning-bg-color,
                    var.$warning-border-color
                );
            }
            
            .signin-success-message {
                @include mixins.alert-box(
                    var.$success-color,
                    var.$success-bg-color,
                    var.$success-border-color
                );
            }

            .signin-error-message {
                @include mixins.alert-box(
                    var.$alert-color,
                    var.$alert-bg-color,
                    var.$alert-border-color
                );
            }
        }
        
        .show-password-reset {
            right: 350px;
        }
    }
}

@media only screen and (max-height: 430px) {
    .signin-container {
        .signin-body-header {
            display: none; 
        }
    }
}