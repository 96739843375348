.header-title {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin: -1px 0 0 0;
    font-weight: 600;
    font-size: 24px;
    width: 230px;
}

.header-legend {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    margin: 9px 0 0 0;
    width: 230px;
    height: 36px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    font-size: 12px;
    font-weight: 400;

    .header-legend-top {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        height: 18px;
        display: flex;
        
        & span {
            padding: 0 10px 0 2px;
        }
    }

    .header-legend-bottom {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        height: 18px;
        display: flex;

        & span {
            padding: 0 10px 0 2px;
        }
    }

    .leak-circle {
        margin: 4px 0 0 0;
        width: 9px;
        height: 9px;
        border-radius: 50%;
    }

    .production-circle {
        border: thin solid rgb(6, 122, 94);
        background-color: rgb(6, 122, 94, 0.25);
    }

    .combined-circle {
        border: thin solid rgb(116, 44, 28);
        background-color: rgb(116, 44, 28, 0.25);
    }

    .trunk-circle {
        border: thin solid rgb(255, 195, 2);
        background-color: rgb(255, 195, 2, 0.25);
    }

    .gathering-cirlce {
        border: thin solid rgb(255, 91, 0);
        background-color: rgb(255, 91, 0, 0.25);
    }
}

@media screen and (min-width: 736px) {
    .header-title {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        justify-self: center;
        align-self: center;
        text-align: center;
    }

    .header-legend {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        margin: 0;

    }
}