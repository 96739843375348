// Typography
$font-family: 'Open Sans', sans-serif; 
$font-default-size: 0.875rem;

// Font colors
$primary-color: #212529;
$secondary-color: #424656;
$small-color: #98A6AD;
$alert-color: #5a0e15;
$success-color: rgb(23, 78, 63);
$error-color: #DC3545;
$warning-color: #856404;

// Background colors
$primary-bg-color: #F0F0F0;
$secondary-bg-color: #FFFFFF;
$nav-bg-color: #004c6d;
$alert-bg-color: #F8D7DA;
$success-bg-color: rgb(194, 243, 229);
$warning-bg-color: #FFF3CD;
$hover-bg-color: #F3F3F3;
$darkMode-bg-color: #212121;

// Border colors & shadows
$card-border: #D8D8D8;
$input-border-color: #727272;
$signin-input-border: #BEBEBE;
$alert-border-color: #E9979E;
$success-border-color: rgb(120, 179, 162);
$warning-border-color: #d4bb70;
$dropdown-border-color: #AEAEAE;
$drop-shadow: drop-shadow(0px 0px 3px rgba(149, 149, 149, 0.79));
$drop-shadow-bottom: drop-shadow(0px 3px 3px rgba(149, 149, 149, 0.79));

// Buttons, Links & Icons colors
$btn-bg-blue: #004c6d;
$btn-bg-blue-hover: #022140;
$btn-box-shadow-blue-focus: #99BDE0;
$btn-bg-alert: #DC3545;
$btn-bg-alert-hover: #a01422;
$btn-box-shadow-alert-focus: #ebb1b7;
$btn-bg-green: #078f6f;
$btn-bg-green-hover: #067057;
$btn-box-shadow-green-focus: #85c4b5;
$link-primary: #007BFF;
$link-primary-hover: #005DC0;
$icon-alert-bg-color: rgb(250, 92, 124);
$icon-alert-bg-color-fade: rgb(250, 92, 124, 0.4);
$icon-success-bg-color: rgb(14, 187, 138);
$icon-success-bg-color-fade: rgb(14, 187, 138, 0.4);
$icon-paused-bg-color: rgb(239, 217, 32);
$icon-paused-bg-color-fade: rgb(239, 217, 32, 0.4);
$icon-primary-bg-color: #727CF5;
