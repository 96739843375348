@use '../variables' as var;

.notification-container {
    width: 100%;
    display: flex;
    padding: 8px 13px;

    .noti-icon-bg {
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    .noti-message {
        // calc: padding - message icon width - delete icon width
        max-width: calc(100% - 13px - 36px - 24px); 
        margin: 0 0 0 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        & p {
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .noti-delete-btn {
        margin: 0 0 0 auto;
        display: flex;
        align-self: center;

        &:hover {
            cursor: pointer;
            transform: scale(1.1);
        }
    }

    .noti-icon-alert {
        background-color: var.$icon-alert-bg-color;
    }
    .noti-icon-success {
        background-color: var.$icon-success-bg-color;
    }
    .noti-icon-primary {
        background-color: var.$icon-primary-bg-color;
    }
}

@media screen and (min-width: 736px) {
    .notification-container {
        .noti-message {
            & p {
                font-size: 13px;
            }
        }
    }
}