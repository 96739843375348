@use '../../mixins';
@use '../../variables' as var;

.realtime-trunk {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    row-gap: 20px;

    .rateTotals {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .lossFactors {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }
    
    .pressures-container {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}