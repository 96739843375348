.password-request-container {
    grid-column: 2 / 3;
    width: 100%;
    padding: 40px 40px 45px 40px;     
    position: relative;   
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    .password-request-back-btn {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        align-items: center;

        span {
            font-weight: 400;
            font-size: .75rem;
        }

        img {
            width: 22px;
            height: 22px;
            transform: scaleX(-1);
        }

        &:hover {
            cursor: pointer;
        }
    }

    .password-request-header {
        margin: 0 0 15px 0;
        width: 100%;
        font-size: 16px;
        text-align: center;
    }

    .password-request-messages {
        margin: 0 0 15px 0;
    }

    .password-request-form {
        margin: 15px 0 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            margin: 0 0 5px 0;
        }
    }
}