@use '../mixins';
@use '../variables' as var;

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 9999;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: modal-opacity 0.15s;
  overflow-y: scroll;

  @keyframes modal-opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .modal-body {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    width: 100%;
    min-width: 280px;
    padding: 40px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    animation: modal-scale 0.15s;

      @keyframes modal-scale {
          0% {
            transform: scale(0.9);
          }
          100% {
            transform: scale(1);
          }
      }
      
      form {
          display: flex;
          flex-direction: column;
      }

      .modal-loader {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #FFFFFF;
        opacity: 0.7;
        z-index: 1;
      }
        
        .modal-close {
          position: absolute;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 10px;
          right: 10px;
          cursor: pointer;

          &:hover {
            transform: scale(1.1);
          }

          &:active {
            outline: none;
          }
        }

        .modal-success-message {
            margin: 20px 0 0 0;
            width: 100%;
            @include mixins.alert-box(
                var.$success-color,
                var.$success-bg-color,
                var.$success-border-color
            );
            min-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .modal-error-message {
            margin: 20px 0 0 0;
            width: 100%;
            @include mixins.alert-box(
                var.$alert-color,
                var.$alert-bg-color,
                var.$alert-border-color
            );
            min-height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (min-width: 450px) {
  .modal-container {
    .modal-body {
      width: 450px;
      padding: 40px;
      border-radius: 5px;
    }
  }
}