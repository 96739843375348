@use './variables' as var;

@mixin alert-box($textColor, $bgColor, $borderColor) {
    padding: .5rem 1.25rem;
    background-color: $bgColor;
    border: thin solid $borderColor;
    border-radius: 3px;
    text-align: center;
    color: $textColor;
}

@mixin realtime-section_default {
    padding: 20px;
    background-color: var.$secondary-bg-color;
    border: thin solid var.$card-border;
}

@mixin realtime-section_736-left {
    margin: 0 5px 0 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

@mixin realtime-section_736-right {
    margin: 0 0 0 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

@mixin cumulative-section_default {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    background-color: var.$secondary-bg-color;
    border: thin solid var.$card-border;
}