.credentials-container {
    grid-column: 1 / 2;
    width: 100%;
    padding: 40px 40px 45px 40px;
    position: relative;   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    background-color: #FFFFFF;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    .credentials-header {
        margin: 0 0 15px 0;
        width: 100%;
        font-size: 16px;
        text-align: center;
    }

    .credentials {
        width: 100%;
        display: flex;
        flex-direction: column;

        .credentials-messages {
            display: flex;
            flex-direction: column;
            
            div:nth-last-child(1) {
                margin: 0 0 15px 0;
            }

            div:nth-last-child(2) {
                margin: 0 0 5px 0;
            }
        }

        label {
            margin: 0 0 5px 0;
        }

        .credentials-label-pw {
            display: flex;
            justify-content: space-between;

            .pw-reset-signin-btn {
                font-weight: 400;
                font-size: .75rem;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}