@use '../../variables' as var;

.date-picker {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    margin: 16px 0 0 0;
    width: calc(10.625rem + 60px);
    position: relative;
    display: grid;
    grid-template-columns: 2.1rem 8.525rem 60px;
    grid-template-rows: 2.25rem;

    .calendar-load-status {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        place-items: center;
        
        & span {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            font-size: 12px;
            font-weight: 400;
        }
        .calendar-load-icon {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            position: relative;
            top: -2px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }

        .calendar-icon-Live {
            background-color: var.$icon-success-bg-color-fade;
            border: thin solid var.$icon-success-bg-color ;
        }

        .calendar-icon-Paused {
            background-color: var.$icon-paused-bg-color-fade;
            border: thin solid var.$icon-paused-bg-color ;
        }

        .calendar-icon-Error {
            background-color: var.$icon-alert-bg-color-fade;
            border: thin solid var.$icon-alert-bg-color ;
        }
    }

    .react-datepicker-popper {
        z-index: 2 !important;
    }

    .react-datepicker {
        margin: -4px 0 0 0;
        border-radius: 3px;
        font-family: var.$font-family !important;

        .react-datepicker__current-month{
            font-weight: 600 !important;
        }

        .react-datepicker__header{
            background-color: #FFFFFF !important;
            border-radius: 3px !important;
        }

        .react-datepicker__day--selected {
            background-color: var.$nav-bg-color !important;
        }
    }

    .date-picker-loader {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        position: absolute;
        width: 100%;
        height: 2.25rem;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 1;

        .date-picker-spinner {
            position: absolute;
            right: 5px;
            height: 100%;
            width: 50px;
            background-color: rgba(255, 255, 255);
        }
    }

    .date-picker-btn {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: var.$nav-bg-color;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .date-picker-input {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        height: 2.25rem;
        width: 8.525rem; 
        text-align:center;
        background-color: #f7f7f7;
        border: thin solid var.$signin-input-border;
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-left: none;
        font-family: var.$font-family;
        font-size: 13px;
        font-weight: 400;
        color: #212529;
        &:focus {
            outline: 0;
        }    
    }
}

@media screen and (min-width: 540px) {
    .date-picker {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        justify-self: end;
        align-self: center;
        margin: 0;
        grid-template-columns: 60px 8.525rem 2.1rem;

        .calendar-load-status {
            grid-column: 1 / 2;
        }
        
        .date-picker-btn {
            grid-column: 3 / 4;
            border-radius: 0;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        .date-picker-input { 
            grid-column: 2 / 3;
            border: thin solid var.$signin-input-border;
            border-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-right: none;
        }

        .date-picker-loader {
            .date-picker-spinner {
                left: 5px;
            }
        }
    }
}

@media screen and (min-width: 736px) {
    .date-picker {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
    }
}