@use '../mixins';
@use '../variables' as var;

.change-picture-header {
    width: calc(100% - 40px);
    height: 110px;
    margin: 0 20px;
    border-radius: 5px;
    // background-color: rgb(6, 122, 94, 0.25);
    background-color: #005a96c0;
    position: absolute;
    top: 20px;
    left: 0;
}

.change-picture-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 3px solid #FFFFFF;
    z-index: 1;
    cursor: pointer;
}

.change-picture-upload-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    border-radius: 50%;
    background-color: #FFFFFF;
    border: 5px solid #FFFFFF;
    z-index: 2;
    top: 150px;
    left: calc(50% + 30px);
    cursor: pointer;
}

.change-picture-name {
    margin: 10px 0 0 0;
    text-align: center;
    display: flex;
    font-size: 16px;
    flex-direction: column;
    text-align: center;

    span:first-child{
        font-weight: 600;
        font-size: 18px;
    }
}

.change-picture-contacts {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    text-align: center;
}


.change-picture-modal {
    width: 100%;
    display: flex;
    flex-direction: column;

    .change-picture-cropper {
        width: 100%;
        height: 200px;
        position: relative;

        .reactEasyCrop_Container{
            border-radius: 5px;
        }
    }
    
    .change-picture-slider {
        -webkit-appearance: none;
        appearance: none;
        margin: 40px 0 0 0;
        width: 100%;
        height: 3px;
        outline: none;
        cursor: pointer;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: rgb(0,76,109);
        }

        &::-moz-range-thumb {
            height: 15px;
            width: 15px;
            border-radius: 50%;
            background-color: var.$btn-bg-blue;
        }


    }
    .thumb-hovered::-webkit-slider-thumb{
        box-shadow: 0 0 0 7px rgba(0,76,109, 0.2);    
    }

    .thumb-clicked::-webkit-slider-thumb{
        box-shadow: 0 0 0 13px rgba(0,76,109, 0.2);  
    }

    .change-picture-options {
        margin: 40px 0 0 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        input[type="file"] {
            display: none;
        }

        .change-picture-upload {
            margin: 0 10px 0 0;
            width: 50%;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var.$btn-bg-blue;
            font-weight: 400;

            &:hover {
                background-color: var.$btn-bg-blue-hover;
            }
          
            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px var.$btn-box-shadow-blue-focus;
            }
        }

        .change-picture-crop {
            margin: 0 0 0 10px;
            width: 50%;
            height: 42px;
        }

    }
}

.change-picture-error-message {
    margin: 20px 0 0 0;
    width: 100%;
    @include mixins.alert-box(
        var.$alert-color,
        var.$alert-bg-color,
        var.$alert-border-color
    );
}