.dashboard-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 
        3.25rem
        auto
        1fr;
    row-gap: 20px;
}