@use '../mixins';
@use '../variables' as var;

.manage-users-container {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: minmax(280px, 100%);
    grid-template-rows: auto auto;
    row-gap: 20px;
    position: relative;

    .update-delete-body {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 20px;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        border: thin solid var.$card-border;

        .update-delete-options {
            margin: 0 0 20px 0;
            width: 100%;
            max-width: 340px;
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: auto auto;
            column-gap: 20px;
            row-gap: 20px;

            p {
                grid-column: 1 / 3;
                grid-row: 1 / 2;
                display: flex;
                align-items: flex-end;
            }

            .manage-users-update-btn {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                margin: 0;
                max-width: 160px;
            }

            .manage-users-delete-btn {
                grid-column: 2 / 3;
                grid-row: 2 / 3;
                margin: 0;
                max-width: 160px;
            }
        }

        .update-delete-list {
            width: 100%;
            height: 360px;

            .update-delete-row {
                width: 100%;
                display: grid;
                grid-template-columns: 48px 1fr;
                grid-template-rows: 39px;
                align-items: center;
                border-top: thin solid #E0E0E0;

                &:first-child {
                    background-color: rgba(131, 131, 131, 0.4);
                    border: none;
                    border-radius: 3px;
                    font-weight: 600;
                }

                &:last-child {
                    border-bottom: thin solid #E0E0E0;
                }

                input {
                    grid-column: 1 / 2;
                    margin: 0 auto;
                    -webkit-appearance: radio;
                    -moz-appearance: radio;
                    appearance: radio;
                    cursor: pointer;
                }

                label {
                    grid-column: 2 / 3;
                    height: 100%;
                    display: grid;
                    grid-template-columns: 45% 55%;
                    grid-template-rows: auto;
                    cursor: pointer;
                    
                    span {
                        width: 100%;
                        margin: auto 0;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        position: sticky;

                        &:nth-last-child(1){
                            display: none;
                        }
                        
                        &:nth-last-child(2){
                            display: none;
                        }
                    }
                }

                .update-delete-auto-cursor {
                    cursor: auto;
                }
            }
        }
        
        .update-delete-pages {
            padding: 20px 0 0 0;
            margin: auto 0 0 0;
            width: 100%;
            max-width: 250px;
            height: 52px;
            display: flex;
            justify-content: center;
            align-self: center;
            overflow: hidden;

            li {
                display: inline;
                border-top: thin solid #E0E0E0;
                border-bottom: thin solid #E0E0E0;
                border-left: thin solid #E0E0E0;
                text-decoration: none;
                text-align: center;
                user-select: none;

                a {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:focus {
                        outline: none;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            li:nth-child(1) {
                margin: 0 auto 0 0;
                border-right: thin solid #E0E0E0;
                border-radius: 3px;
            }

            li:nth-child(2) {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            li:nth-last-child(2) {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-right: thin solid #E0E0E0;

            }

            li:nth-last-child(1) {
                margin: 0 0 0 auto;
                border-right: thin solid #E0E0E0;
                border-radius: 3px;
            }

            .update-delete-active {
                background-color: rgba(131, 131, 131, 0.4);
                font-weight: 600;
            }
        }
    }

    .create-user-body {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        border: thin solid var.$card-border;

        label {
            margin: 0 0 5px 0;
        }
    }

    .manage-users-loading {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background-color: var.$primary-bg-color;

        .manage-users-loading-animation {
            width: 100%;
            height: calc(100% - 104px);
            position: fixed;
            top: 52px;
            left: 0;
        }
    }

    .manage-users-red-btn {
        background-color: var.$btn-bg-alert;

        &:hover {
            background-color: var.$btn-bg-alert-hover;
        }
        
        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px var.$btn-box-shadow-alert-focus;
        }
    }

    .manage-users-header {
        margin: -1px 0 15px 0;
        width: 100%;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
    }

    .manage-users-success-message {
        margin: 20px 0 0 0;
        width: 100%;
        @include mixins.alert-box(
            var.$success-color,
            var.$success-bg-color,
            var.$success-border-color
        );
        min-height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .manage-users-error-message {
        margin: 20px 0 0 0;
        width: 100%;
        @include mixins.alert-box(
            var.$alert-color,
            var.$alert-bg-color,
            var.$alert-border-color
        );
        min-height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



@media screen and (min-width: 600px) {
    .manage-users-container {
        .update-delete-body {
            margin: 0 20px;
            border-radius: 5px;

            .update-delete-options {
                margin: 0 0 10px 0;
                max-width: 100%;
                grid-template-columns: 1fr auto auto;
                grid-template-rows: auto;

                p {
                    grid-column: 1 / 2;
                    grid-row: 1 / 2;
                }
    
                .manage-users-update-btn {
                    grid-column: 2 / 3;
                    grid-row: 1 / 2;
                    width: 160px;
                }
    
                .manage-users-delete-btn {
                    grid-column: 3 / 4;
                    grid-row: 1 / 2;
                    width: 160px;
                }
            }

            .update-delete-list {
                .update-delete-row {
                    label {
                        grid-template-columns: 19% 22% 37% 18%;
                        column-gap: 1%;
                        span {
                            &:nth-last-child(1){
                                display: block;
                            }
                            &:nth-last-child(2){
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .create-user-body {
            margin: 0 20px;
            border-radius: 5px;
        }
    }
}

@media screen and (min-width: 1024px) {
    .manage-users-container {
        grid-template-columns:
            auto
            minmax(auto, 841px)
            minmax(200px, 400px)
            auto;
        grid-template-rows: auto;
        row-gap: 0;
        column-gap: 20px;

        .update-delete-body {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            margin: 0;
        }
    
        .create-user-body {
            grid-column: 3 / 4;
            grid-row: 1 / 2;
            margin: 0;
        }
    }
}