@use '../../mixins';
@use '../../variables' as var;

.cumulative-trunk {
    display: grid;

    .daily-container {
        @include mixins.cumulative-section_default;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}