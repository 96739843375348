@use '../../mixins';
@use '../../variables' as var;

.cumulative-producer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    row-gap: 20px;

    .table-container {
        @include mixins.cumulative-section_default;
        grid-column: 1;
        grid-row: 1 / 2;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .monthly-container {
        @include mixins.cumulative-section_default;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .daily-container {
        @include mixins.cumulative-section_default;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }
}

@media screen and (min-width: 580px){
    .cumulative-producer {
        .table-container {
            max-width: 567px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

@media screen and (min-width: 900px) {
    .cumulative-producer {
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;

        .table-container {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        .monthly-container {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            margin: 0 0 0 10px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        .daily-container {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
        }
    }
}

@media screen and (min-width: 1024px) {
    .cumulative-producer {
        .table-container {
            border-radius: 5px;
        }
    
        .monthly-container {
            border-radius: 5px;
            margin: 0 0 0 20px;
        }
    
        .daily-container {
            border-radius: 5px;
        }
    }
}