@mixin cumulative-table-lables_default {
    display: grid;
    place-content: center;
    border-top: thin solid #E0E0E0;
    border-right: thin solid #E0E0E0;

    & p {
        font-weight: 600;

        & span {
            font-size: 11px;
        }
    }
}

.cumulative-table-wrapper {
    grid-column: 1 / 2;
    grid-row: 2 / 3;    // grid-row 1 / 2 is section-header
    padding: 20px 0 0 0;
    width: 545px;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(6, 48px);

    .cumulative-table-row {
        display: grid;
        grid-column: 1 / 2;
        grid-template-columns: repeat(auto-fill, 105px) [col-end];
        grid-template-rows: 1fr;

        &:nth-child(2) {
            & .cumulative-table-cell {
                background-color: rgb(255, 91, 0, 0.4);
                border-bottom: none;
                
                & span {
                    color: #161616;
                    font-weight: 600;
                }
            }
        }

        &:last-child{
            & .cumulative-table-cell {
                border-bottom: none;
            }

            & .cumulative-table-cell:last-child{
                border-bottom-right-radius: 3px;
            }
        }

        .cumulative-table-label-totals {
            @include cumulative-table-lables_default;
            grid-column: 2 / 4;
            border-left: thin solid #E0E0E0;
            border-top-left-radius: 3px;
        }

        .cumulative-tablel-lable-pipelines {
            @include cumulative-table-lables_default;
            grid-column: 4 / col-end;
            border-top-right-radius: 3px;
        }

        .cumulative-table-cell{
            display: grid;
            place-content: center;
            background-color: #F4F4F4;
            border-bottom: thin solid #E0E0E0;

            &:first-child {
                background-color: rgb(131, 131, 131, 0.4);
                border-bottom:  rgb(131, 131, 131, 0.4) !important;

                & span {
                    color: #161616;
                    font-weight: 600;
                }
            }

            &:nth-child(3) {
                border-right: thin solid #E0E0E0;
            }
        }

        .cell-0-0 {
            background-color: #FFFFFF !important;
            font-size: 12px;
            font-style: italic;
        }

        .cell-0-1 {
            background-color: rgb(6, 122, 94, 0.4) !important;
        }

        .cell-0-2 {
            background-color: rgb(116, 44, 28, 0.4) !important;
            border-right: none !important;
        }

        .cell-0-3 {
            background-color: rgb(255, 195, 2, 0.4) !important;
        }

        .cell-1-0 {
            border-top-left-radius: 3px;
        }

        .cell-4-0 {
            border-bottom-left-radius: 3px;
        }
    }
}