@use '../../mixins';
@use '../../variables' as var;

.realtime-producer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, auto);
    row-gap: 20px;

    .lossVolumes {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    .tlProperties {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
    }

    .glProperties {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 3 / 4;
    }

    .crudeProperties {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 4 / 5;
    }

    .pressures-container {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 5 / 6;
    }

    .weekly-container {
        @include mixins.realtime-section_default;
        grid-column: 1 / 2;
        grid-row: 6 / 7;
    }
}

@media screen and (min-width: 736px) {
    .realtime-producer {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, auto);

        .lossVolumes {
            @include mixins.realtime-section_736-left;
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        .glProperties {
            @include mixins.realtime-section_736-left;
            grid-column: 1 / 2;
            grid-row: 2 / 3;
        }

        .crudeProperties {
            @include mixins.realtime-section_736-left;
            grid-column: 1 / 2;
            grid-row: 3;
            height: 323px;
        } 

        .tlProperties {
            @include mixins.realtime-section_736-right;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }

        .pressures-container {
            @include mixins.realtime-section_736-right;
            grid-column: 2 / 3;
            grid-row: 2 / 3;
        }

        .weekly-container {
            @include mixins.realtime-section_736-right;
            grid-column: 2 / 3;
            grid-row: 3 / 4;
        }
    }
}

@media screen and (min-width: 1024px) {
    .realtime-producer {
        grid-template-columns: repeat(3, minmax(0, 425px));
        grid-template-rows: repeat(3, auto);

        // first row
        .lossVolumes {
            grid-column: 1 / 4;
            grid-row: 1 / 2;
            padding: 20px;
            margin: 0;
            border-radius: 5px;

            & > .spark-rows-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: 1fr;

                .spark-row {
                    grid-template-columns: 1fr;
                    grid-template-rows: 38px 55px;
                    border: none;

                    .spark-text {
                        grid-column: 1 / 2;
                        grid-row: 1 / 2;
                        padding: 0 0 17px 0;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-end;

                        & p {
                            margin: 0 0 -1px auto;
                        }
                    }

                    .spark-chart {
                        grid-column: 1 / 2;
                        grid-row: 2 / 3;
                    }

                    &:nth-child(1) {
                        grid-column: 1 / 2;
                        grid-row: 1 / 2;
                        margin: 0 5px 0 0;
                        padding: 17px 20px 0 0;
                        height: 110px;
                        border-right: thin solid var.$primary-bg-color;
                    }
                    
                    &:nth-child(2) {
                        grid-column: 2 / 3;
                        grid-row: 1 / 2;
                        padding: 17px 12px 0 14px;
                        height: 110px;
                    }

                    &:nth-child(3) {
                        grid-column: 3 / 4;
                        grid-row: 1 / 2;
                        margin: 0 0 0 7px;
                        padding: 17px 0 0 20px;
                        height: 110px;
                        border-left: thin solid var.$primary-bg-color;                    
                    }
                }
            }
        }

        // second row
        .tlProperties {
            grid-column: 1 / 2;
            grid-row: 2;
            height: 323px;
            margin: 0 10px 0 0;
            border-radius: 5px;
        }

        .glProperties {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            margin: 0 10px 0 10px;
            border-radius: 5px;
        }

        .pressures-container {
            grid-column: 3 / 4;
            grid-row: 2 / 3;
            margin: 0 0 0 10px;
            border-radius: 5px;
        }

        // thrid row
        .crudeProperties {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            position: relative;
            top: -192px;
            margin: 0 10px 0 0;
            border-radius: 5px;
        }

        .weekly-container {
            grid-column: 2 / 4;
            grid-row: 3 / 4;
            margin: 0 0 0 10px;
            border-radius: 5px;
        }
    }
}