.footer {
    padding: 0 0 20px 0;
    grid-column: 1;
    grid-row: 3 / 4;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    color: #696969 !important;
}