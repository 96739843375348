@use './variables' as var;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
  
html, body, #root {
  height: 100%;
}
  
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var.$font-family;
  font-size: var.$font-default-size;
  background-color: var.$primary-bg-color;
  color: var.$primary-color;
}
  
a, button, input {
  font-family: var.$font-family;
}

p {
  font-size: 0.875rem;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.no-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
  
.mt-10 {
  margin-top: 0.625rem;
}

.small-text {
  font-size: 0.75rem;
  font-weight: 400;
  color: var.$small-color;
}

.dark-mode {
    background-color: rgb(39, 39, 39);
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.7;
  background-color: #FFFFFF;
}

.global-form-label {
  margin: 0 0 5px 0;
}

.global-form-input {
  width: 100%;
  height: 38px;
  padding: 0 0 0 .625rem;
  margin: 0 0 15px 0;
  border: none;
  box-shadow: 0 0 0 1px var.$signin-input-border;
  background-color: #f7f7f7;
  border-radius: 3px;
  font-size: 14px;

  &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var.$signin-input-border !important;
  }

  &:invalid {
      box-shadow: 0 0 0 1px var.$signin-input-border;
  }
}

.global-submit-btn {
  width: 100%;
  height: 42px; 
  margin: 5px 0 0 0;
  background-color: var.$btn-bg-green;
  border: none;
  border-radius: 3px; 
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  transition: 
      background-color .15s ease-in-out,
      box-shadow .15s ease-in-out;

  &:hover {
      background-color: var.$btn-bg-green-hover;
  }

  &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var.$btn-box-shadow-green-focus;
  }
}