.highcharts-credits {
    display: none !important;
  }
  
.highcharts-container, 
.highcharts-container svg { 
  width: 100% !important; 
}

.tooltip-custom {
  width: 150%;
  height: 150%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.highcharts-contextbutton,
.highcharts-button-box {
  cursor: pointer !important;
}

.highcharts-menu {
  border-radius: 3px !important;
}