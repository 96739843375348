@use '../../variables' as var;

.header-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    padding: 20px;
    background-color: var.$secondary-bg-color;
}

@media screen and (min-width: 540px) {
    .header-container {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto;
    }
}

@media screen and (min-width: 736px) {
    .header-container {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }
}

@media screen and (min-width: 1024px) {
    .header-container {
        padding: 20px;
        border-radius: 5px;
    }
}