@use '../variables' as var;

@mixin btn-alignment {
    align-self: center;
    justify-self: end;
    height: 26px;
    width: 26px;
}

@mixin nav-dropdown-container_736px {
    position: relative;
    top: 13px;
    left: auto;
    border: none;
    border: thin solid var.$dropdown-border-color;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: none;

    .nav-dropdown-row {
        padding: 10px;
        font-size: 13px;
        
        &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

nav {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 
        20px 
        1fr 
        repeat(4, 3rem)
        20px;
    background-color: var.$nav-bg-color;

    .nav-brand {
        grid-column: 2 / 3;
        align-self: center;
        display: flex;
        align-items: center;
        
        & span {
            display: none;
            margin: 0 0 0 15px;
            color: #FFFFFF;
            font-weight: 600;
        }
    }

    .nav-dashboards {
        grid-column: 3 / 4;
        // grid-column: 4 / 5;
        @include btn-alignment;
    }

    .nav-calculators {
        grid-column: 4 / 5;
        @include btn-alignment;
    }

    .nav-notifications {
        grid-column: 5 / 6;
        @include btn-alignment;

        .nav-noti-status {
            width: 18px;
            height: 18px;
            z-index: 1;
            position: absolute;
            top: -5px;
            left: 10px;
            display: grid;
            place-items: center;
            border-radius: 50%;
            background-color: var.$icon-alert-bg-color;
            font-weight: 700;

            & span {
                height: 100%;
                font-size: 12px;
                color: #FFFFFF;
            }
        }

        .nav-noti-wrapper {
            outline: none;

            .nav-dropdown-container {
                cursor: auto;

                .nav-dropdown-row  {
                    padding: 0;
            
                    &:first-child {    
                        padding: 8px 13px 8px 13px;
                        border: none;
            
                        &:hover {
                            background-color: var.$secondary-bg-color;
                            color: #000000;
                            cursor: auto;
                        }
                    }
                    &:nth-last-child(2) {
                        border: none;
                    }
            
                    &:last-child {
                        padding: 10px 0 10px 0;
                        justify-content: center;
                        color: var.$link-primary;
                        cursor: auto;

                        & span {
                            cursor: pointer;

                            &:hover {
                                color: var.$link-primary-hover;
                            }
                        }

                        &:hover {
                            background: none;
                        }
                    }
                }
  
            }

            .nav-noti-cleared {
                pointer-events: none;
                padding: 13px;
                font-size: 15px;
                font-weight: 600;
                text-align: center;
            }
        }
    }

    .nav-user {
        grid-column: 6 / 7;
        @include btn-alignment;
    }

    .nav-btn {
        height: 26px;
        width: 26px;
        position: relative;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
    
        &:active .nav-btn-bg {
            transform: scale(1.05)
        }
    
        & .nav-btn-bg {
            width: 38px;
            height: 38px;
            position: absolute;
            top:-6px;
            left: -6px;
            background-color: #FFFFFF;
            border-radius: 50%;
            opacity: 0.2;
            pointer-events: none;
        }
    }

    .nav-dropdown-container {
        width: 100vw;
        position: absolute;
        top: 52px;
        left: 0;
        display: flex;
        flex-direction: column;
        z-index: 9999;
        background-color: var.$secondary-bg-color;
        text-decoration: none;
        cursor: pointer;

        .nav-dropdown-row {
            padding: 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: thin solid var.$primary-bg-color;
            font-size: 15px;
            font-weight: 600;
            color: var.$primary-color;
            text-decoration: none;
 
            &:hover {
                background-color: var.$primary-bg-color;
                color: #000000;

                & > img {
                    transform: scale(1.1);
                }
            }
        }

        &:last-child {
            border-bottom: thin solid var.$dropdown-border-color;
        }
    }
}

@media screen and (min-width: 540px) {
    nav .nav-brand span {
        display: block;
    }
}

@media screen and (min-width: 736px) {
    .nav-dashboards {
        .nav-dropdown-container {
            @include nav-dropdown-container_736px;
            width: 200px;
            right: 168px;
        }
    }

    .nav-notifications {
        .nav-noti-wrapper {
            .nav-dropdown-container {
                @include nav-dropdown-container_736px;
                width: 325px;
                right: 293px;
            }
            .nav-noti-cleared {
                width: 225px !important;
                right: 193px !important;
                padding: 10px !important;
                font-size: 13px !important;
            }
        }
    }

    .nav-calculators {
        .nav-dropdown-container {
            @include nav-dropdown-container_736px;
            width: 200px;
            right: 168px;
        }
    }

    .nav-user {
        .nav-dropdown-container {
            @include nav-dropdown-container_736px;
            width: 200px;
            right: 168px;
        }
    }
}

@media screen and (min-width: 1024px) {
    nav {
        grid-template-columns: 
            minmax(40px, auto) 
            minmax(0, 1043px) 
            repeat(4, 3rem)
            minmax(40px, auto);    
    }
}