@use '../mixins';
@use '../variables' as var;

.report-issue-container {
    width: 100%;
    height: calc(100vh - 3.25rem - 54px - 40px);
    grid-column: 1 / 2;
    grid-row: 2 / 3;

    .report-issue-body {
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        position: relative;
        border: thin solid var.$card-border;

        .report-issue-loading {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #FFFFFF;
            opacity: 0.7;
            z-index: 1;        
        }

        .report-issue-header {
            margin: -1px 0 15px 0;
            width: 100%;
            font-weight: 600;
            font-size: 24px;
            text-align: center;
        }

        .report-issue-form {
            width: 100%;
            display: flex;
            flex-direction: column;

            label {
                margin: 0 0 5px 0;
            }
        }
    }

    .report-issue-success-message {
        margin: 20px 0 0 0;
        width: 100%;
        @include mixins.alert-box(
            var.$success-color,
            var.$success-bg-color,
            var.$success-border-color
        );
    }
    
    .report-issue-error-message {
        margin: 20px 0 0 0;
        width: 100%;
        @include mixins.alert-box(
            var.$alert-color,
            var.$alert-bg-color,
            var.$alert-border-color
        );
    }
}

@media screen and (min-width: 736px) {
    .report-issue-container {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .report-issue-body {
            width: 450px;
            border-radius: 5px;
            padding: 40px;
        }
    }
}

@media screen and (max-height: 644px) {
    .report-issue-container {
        height: 100%;
    }
}